<template>
    <div class='video-component'>
        <video-player
          class="video-player vjs-custom-skin"
          ref="videoPlayer"
          :playsinline="true"
          :options="playerOptions">
        </video-player>
    </div>
</template>
<script>
import Vue from 'vue';
import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
Vue.use(VideoPlayer)
  export default {
    props:{
      videoUrl: { // PC预览模块传入组件--->videoBanner
        type: String,
        default: ''
      },
      isAuto:{
          type: Boolean,
          default: true
      }
    },
    data() {
      return {
        playerOptions: {
          //播放速度
          playbackRates: [0.5, 1.0, 1.5, 2.0],
          //如果true,浏览器准备好时开始回放。
          autoplay: this.isAuto?true:false,
          // 默认情况下将会消除任何音频。
          muted: this.isAuto? true: false,
          // 导致视频一结束就重新开始。
          loop: this.isAuto?true: false,
          // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          preload: 'auto',
          language: 'zh-CN',
            // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          aspectRatio: '16:9',
            // 当true时，Video.js player将拥有流体大小。换句话说,它将按比例缩放以适应其容器。
          fluid: true,
          sources: [{
            //类型
            type: "video/mp4",
            //url地址
            src: ''
          }],
          //你的封面地址
          poster: '',
            //允许覆盖Video.js无法播放媒体源时显示的默认信息。
          notSupportedMessage: '此视频暂无法播放，请稍后再试',
          controlBar: {
            timeDivider: true,
            durationDisplay: true,
            remainingTimeDisplay: false,
            //全屏按钮
            fullscreenToggle: true
          }
        }
      }
    },
    created() {
      let url = this.videoUrl;
      this.playerOptions.sources[0].src = url;
    },
    mounted(){
        console.log('thisisauto', this.isAuto)
         document.getElementsByClassName('vjs-big-play-button')[0].innerHTML = `<div class="video-btn">
            <img src="${require('../../asset/images/video-btn.png')}" alt="">
        </div>`
    }
  }
</script>
<style lang="stylus" scoped>
  .video-component {
    width: 1200px;
    text-align: center;
    line-height: 100px;
    // border: 1px solid transparent;
    border-radius: 4px;
    overflow: hidden;
    background: #fff;
    position: relative;
    // box-shadow: 0 1px 1px rgba(0, 0, 0, .2);
    margin: 0px auto;
    /deep/.video-btn{
        width: 120px;
        height: 120px;
        display inline-block;
        img{
            width 100%;
            height 100%
        }

    }
    /deep/.video-player {
      .vjs-big-play-button {
        left: 50%;
        top: 50%;
        .vjs-icon-placeholder:before {
            font-size: 30px;
        }
      }
    }
    /deep/ .vjs-control-bar{
      display: none;
    }
    /deep/ .vjs-big-play-button{
        // display flex;
        // justify-content center;
        // align-items center;
        width 120px;
        height 120px!important;
        border none;
        background none;
        text-align center;
        line-height 120px!important;
        margin -60px 0 0 -60px!important;
    }
  }
</style>
